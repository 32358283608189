<template>
<section>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-4 pb-3">Página no encontrada :(</h1>
                <p>Lo sentimos, no hemos podido encontrar la página que buscas. 
                    <br>La buena noticia es que puedes volver a la <router-link to="/" class="od_link">página de inicio</router-link> o <router-link to="/contacto" class="od_link">contactarnos</router-link> si crees que ha ocurrido un error grave.
                </p>
            </div>
            
        </div>
    </div>

    </section>
</template>